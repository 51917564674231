import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Card, Flex, List, Progress } from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import { useState } from "react";
import { Link, redirect, useFetcher } from "react-router";
import tailwindConfig from "tailwind.config";
import PagePadding from "~/components/PagePadding";
import { getLoamUser } from "~/modules/auth/user";
import { getCarbonProjectsWithEmissionsReports } from "~/modules/project/service";
import {
  type EmissionsReport,
  type MissingEmissionsReport,
  emissionsReportSchema,
  evidenceRecordSchema,
  missingEmissionsReportSchema,
  paddockCropRecordSchema,
} from "~/modules/reporting/emissions/schema";
import { createEmissionsReport } from "~/modules/reporting/emissions/service";
import {
  getEmissionsReportProgress,
  getEmissionsReportStatus,
} from "~/modules/reporting/emissions/utils";
import type { Route } from "./+types/LandingPage";

export async function loader(args: Route.LoaderArgs) {
  const { organizationIds, role } = await getLoamUser(args);

  const carbonProjects = await getCarbonProjectsWithEmissionsReports(
    role !== "admin" ? organizationIds : undefined, // If the user is an admin, we don't filter by organization
  );

  if (carbonProjects.length === 0) {
    return redirect("/onboarding");
  }

  const reportableProjects = carbonProjects.filter((project) => {
    return project.registeredDate && project.paddocks.length > 0;
  });

  const projectsWithMissingReports = reportableProjects.map((project) => {
    const currentYear = dayjs().year();
    const registeredYear = dayjs(project.registeredDate).year();

    const yearsAlreadyReported = project.emissionsReports.map((report) =>
      dayjs(report.year).year(),
    );
    const yearsOfRegistration = Array.from(
      { length: currentYear - registeredYear + 1 },
      (_, i) => registeredYear + i,
    );
    const missingYears = yearsOfRegistration.filter(
      (year) => !yearsAlreadyReported.includes(year),
    );

    const allReports = [
      ...project.emissionsReports.map((report) => {
        const parsedReport = {
          ...emissionsReportSchema.parse(report),
          evidenceRecords: evidenceRecordSchema
            .array()
            .parse(report.evidenceRecords),
          emissionsPaddockCrops: paddockCropRecordSchema
            .array()
            .parse(report.emissionsPaddockCrops),
        };

        return {
          ...parsedReport,
          status: getEmissionsReportStatus(
            parsedReport,
            project.paddocks.map((paddock) => paddock.id),
          ),
        };
      }),
      ...missingYears.map((year) => {
        return missingEmissionsReportSchema.parse({
          carbonProjectId: project.id,
          year: dayjs(`${year}-01-01`).format("YYYY-MM-DD"),
          submitted: false,
          status: null,
        });
      }),
    ].sort((a, b) => {
      return dayjs(a.year).year() - dayjs(b.year).year();
    });

    return {
      ...project,
      emissionsReports: allReports,
    };
  });

  return { carbonProjects: projectsWithMissingReports };
}

export async function action(args: Route.ActionArgs) {
  const { projectId, year } = await args.request.json();
  const emissionsReport = await createEmissionsReport(projectId, year);

  return redirect(
    `/reporting/emissions/${emissionsReport.id}/project-wide-activities`,
  );
}

export default function ReportingLandingPage({
  loaderData,
}: Route.ComponentProps) {
  const { carbonProjects } = loaderData;
  const fetcher = useFetcher();

  const handleCreateReport = async (projectId: string, year: string) => {
    await fetcher.submit(
      { projectId, year: dayjs(year).format("YYYY-MM-DD") },
      { method: "POST", encType: "application/json" },
    );
  };

  return (
    <PagePadding>
      {carbonProjects.map((project) => {
        return (
          <div key={project.id} className="mb-10">
            <Flex justify="space-between" className="mb-5">
              <h1 className="text-xl font-bold">{project.name}</h1>
            </Flex>
            <List
              grid={{ gutter: 16, column: 3 }}
              dataSource={project.emissionsReports}
              renderItem={(item) => (
                <List.Item>
                  <ReportCard
                    report={item}
                    projectId={project.id}
                    onCreateReport={handleCreateReport}
                  />
                </List.Item>
              )}
            />
          </div>
        );
      })}
    </PagePadding>
  );
}

function ReportCardProgress({
  progress,
}: { progress: { steps: string; percentage: number } }) {
  return (
    <div className="space-y-2 mb-2">
      <p className="text-center">{progress.steps} steps completed</p>
      <Progress
        percent={progress.percentage}
        showInfo={false}
        size={["100%", 30]}
        strokeColor={tailwindConfig.theme.extend.colors.loamGreen}
      />
    </div>
  );
}

function ReportCardButton({
  to,
  disabled,
  children,
  taskCompleted,
}: {
  to: string;
  disabled: boolean;
  children: React.ReactNode;
  taskCompleted?: boolean;
}) {
  return (
    <div className={clsx({ "opacity-50 pointer-events-none": disabled })}>
      <Link to={to}>
        <Button
          block
          className="h-12 flex justify-between shadow-md hover:shadow-lg hover:-translate-y-1 transition-all truncate"
        >
          {children}
          {taskCompleted && (
            <span>
              <CheckCircleTwoTone
                type="right"
                twoToneColor={tailwindConfig.theme.extend.colors.loamGreen}
              />
            </span>
          )}
        </Button>
      </Link>
    </div>
  );
}

function ReportCard({
  report,
  projectId,
  onCreateReport,
}: {
  report: EmissionsReport | MissingEmissionsReport;
  projectId: string;
  onCreateReport: (projectId: string, year: string) => void;
}) {
  const [completedTasksVisible, setCompletedTasksVisible] = useState(false);

  const year = dayjs(report.year).year();
  const progress = getEmissionsReportProgress(report);

  if (!report.status) {
    return (
      <Card
        title={`${year} Project Reporting`}
        className="shadow-md hover:shadow-lg"
      >
        <ReportCardProgress progress={progress} />
        <div className="flex flex-col justify-center space-y-4">
          <Button
            block
            className="h-12 flex justify-between shadow-md hover:shadow-lg hover:-translate-y-1 transition-all truncate"
            onClick={() => onCreateReport(projectId, report.year)}
          >
            Project-wide activities
          </Button>
          <ReportCardButton to="#" disabled>
            Crops
          </ReportCardButton>
          <ReportCardButton to="#" disabled>
            Restricted activities
          </ReportCardButton>
          <ReportCardButton to="#" disabled>
            Evidence
          </ReportCardButton>
        </div>
      </Card>
    );
  }

  return (
    <Card
      title={
        <Link to={`/reporting/emissions/${report.id}`}>
          {year} Project Reporting
        </Link>
      }
      className="shadow-md hover:shadow-lg"
    >
      {!report.submitted ? (
        <>
          <ReportCardProgress progress={progress} />
          <div className="flex flex-col justify-center space-y-4">
            <ReportCardButton
              to={`/reporting/emissions/${report.id}/project-wide-activities`}
              disabled={false}
              taskCompleted={report.status.projectWideActivitiesCompleted}
            >
              Project-wide activities
            </ReportCardButton>
            <ReportCardButton
              to={`/reporting/emissions/${report.id}/crops`}
              disabled={!report.status.projectWideActivitiesCompleted}
              taskCompleted={report.status.cropsCompleted}
            >
              Crops
            </ReportCardButton>
            <ReportCardButton
              to={`/reporting/emissions/${report.id}/restricted-activities`}
              disabled={!report.status.projectWideActivitiesCompleted}
              taskCompleted={report.status.restrictedActivitiesCompleted}
            >
              Restricted activities
            </ReportCardButton>
            <ReportCardButton
              to={`/reporting/emissions/${report.id}/evidence-upload`}
              disabled={!report.status.projectWideActivitiesCompleted}
              taskCompleted={report.status.evidenceUploadCompleted}
            >
              Evidence
            </ReportCardButton>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center space-y-4">
          <div className="flex justify-center space-x-2">
            <p>Submitted</p>
            <span>
              <CheckCircleTwoTone
                type="right"
                twoToneColor={tailwindConfig.theme.extend.colors.loamGreen}
              />
            </span>
          </div>
          {completedTasksVisible ? (
            <div className="flex flex-col justify-center space-y-4">
              <ReportCardButton
                to={`/reporting/emissions/${report.id}/project-wide-activities`}
                disabled={false}
                taskCompleted={report.status.projectWideActivitiesCompleted}
              >
                Project-wide activities
              </ReportCardButton>
              <ReportCardButton
                to={`/reporting/emissions/${report.id}/crops`}
                disabled={!report.status.projectWideActivitiesCompleted}
                taskCompleted={report.status.cropsCompleted}
              >
                Crops
              </ReportCardButton>
              <ReportCardButton
                to={`/reporting/emissions/${report.id}/restricted-activities`}
                disabled={!report.status.projectWideActivitiesCompleted}
                taskCompleted={report.status.restrictedActivitiesCompleted}
              >
                Restricted activities
              </ReportCardButton>
              <ReportCardButton
                to={`/reporting/emissions/${report.id}/evidence-upload`}
                disabled={!report.status.projectWideActivitiesCompleted}
                taskCompleted={report.status.evidenceUploadCompleted}
              >
                Evidence
              </ReportCardButton>
            </div>
          ) : (
            <Button
              type="text"
              onClick={() => {
                setCompletedTasksVisible(true);
              }}
            >
              View details
            </Button>
          )}
        </div>
      )}
    </Card>
  );
}
